exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-post-tsx-content-file-path-content-blog-debugging-on-embedded-mono-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/gedim21.github.io/gedim21.github.io/content/blog/debugging-on-embedded-mono.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-blog-debugging-on-embedded-mono-mdx" */),
  "component---src-templates-post-tsx-content-file-path-content-blog-embedding-mono-generic-method-1-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/gedim21.github.io/gedim21.github.io/content/blog/embedding-mono-generic-method-1.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-blog-embedding-mono-generic-method-1-mdx" */),
  "component---src-templates-post-tsx-content-file-path-content-blog-embedding-mono-generic-method-2-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/gedim21.github.io/gedim21.github.io/content/blog/embedding-mono-generic-method-2.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-blog-embedding-mono-generic-method-2-mdx" */),
  "component---src-templates-post-tsx-content-file-path-content-blog-embedding-mono-in-cpp-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/gedim21.github.io/gedim21.github.io/content/blog/embedding-mono-in-cpp.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-blog-embedding-mono-in-cpp-mdx" */),
  "component---src-templates-post-tsx-content-file-path-content-blog-erc-20-token-using-openzeppelin-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/gedim21.github.io/gedim21.github.io/content/blog/erc20-token-using-openzeppelin.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-blog-erc-20-token-using-openzeppelin-mdx" */),
  "component---src-templates-post-tsx-content-file-path-content-blog-how-to-free-up-disk-space-in-archlinux-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/gedim21.github.io/gedim21.github.io/content/blog/how-to-free-up-disk-space-in-archlinux.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-blog-how-to-free-up-disk-space-in-archlinux-mdx" */),
  "component---src-templates-post-tsx-content-file-path-content-blog-javaee-8-mvc-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/gedim21.github.io/gedim21.github.io/content/blog/javaee8-mvc.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-blog-javaee-8-mvc-mdx" */),
  "component---src-templates-post-tsx-content-file-path-content-blog-local-kafka-cluster-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/gedim21.github.io/gedim21.github.io/content/blog/local-kafka-cluster.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-blog-local-kafka-cluster-mdx" */),
  "component---src-templates-post-tsx-content-file-path-content-blog-mongodb-cluster-docker-compose-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/gedim21.github.io/gedim21.github.io/content/blog/mongodb-cluster-docker-compose.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-blog-mongodb-cluster-docker-compose-mdx" */),
  "component---src-templates-post-tsx-content-file-path-content-blog-setup-a-solidity-project-using-hardhat-and-typescript-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/gedim21.github.io/gedim21.github.io/content/blog/setup-a-solidity-project-using-hardhat-and-typescript.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-blog-setup-a-solidity-project-using-hardhat-and-typescript-mdx" */),
  "component---src-templates-post-tsx-content-file-path-content-blog-springboot-opentelemetry-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/gedim21.github.io/gedim21.github.io/content/blog/springboot-opentelemetry.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-blog-springboot-opentelemetry-mdx" */),
  "component---src-templates-post-tsx-content-file-path-content-blog-unifi-controller-behind-traefik-on-kubernetes-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/gedim21.github.io/gedim21.github.io/content/blog/unifi-controller-behind-traefik-on-kubernetes.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-blog-unifi-controller-behind-traefik-on-kubernetes-mdx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

